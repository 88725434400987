import React from "react"

import Layout from "../components/Layout"
export default function Page404() {
  return (
    <Layout breadcrumbs={["404"]}>
      {/* Heading */}
      <div id="heading" >
        <h1>Página não encontrada</h1>
      </div>

      {/* Main */}
      <section id="main" className="wrapper">
        <div className="inner">
          <div className="content">
            <div className="row">
              <div className="col-6 col-12-medium">

                {/* Text */}
                <h3>Nenhuma página foi encontrada!</h3>

              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
